import { BaseComponent, component } from "aframe-typescript-class-components"

export interface DesktopControlsData {}

@component('desktop-controls')
export class DesktopControls extends BaseComponent<DesktopControlsData> {
  static schema = {}
  static multiple = false
  
  init(): void {
    console.log('desktop-controls init')
    const desktopControls = document.createElement('a-entity')
    desktopControls.setAttribute('cursor', {
      rayOrigin: 'mouse',
      showLine: false,
    })
    desktopControls.setAttribute('raycaster', {
      objects: '.interactive'
    })
    this.el.setAttribute('wasd-controls', 'enabled: false')
    this.el.setAttribute('look-controls', 'enabled: false')
    // this.el.setAttribute('orbit-controls', {
    //   initialPosition: '0 0 -1.3',
    //   minPolarAngle: 45,
    //   maxPolarAngle: 160,
    //   target: '0 0.94 0',
    //   maxDistance: 1.5,
    //   minDistance: 0.5,
    //   enableDamping: true,
    //   enablePan: false,
    //   enableZoom: false,
    // })
    this.el.appendChild(desktopControls)
  }
}