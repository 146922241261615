import { BaseComponent, component } from 'aframe-typescript-class-components'
import { Howler } from 'howler'
import * as THREE from 'three'

@component('howler-listener')
export class HowlerListener extends BaseComponent {
  static multiple = false

  tick(): void {
    Howler.pos(
      this.el.object3D.position.x,
      this.el.object3D.position.y,
      this.el.object3D.position.z
    )
    // Update the orientation of the listener
    const forward = new THREE.Vector3(0, 0, -1)
    forward.applyQuaternion(this.el.object3D.quaternion)
    Howler.orientation(
      forward.x,
      forward.y,
      forward.z,
      0,
      1,
      0
    )
  }
}
