import { BaseComponent, component } from 'aframe-typescript-class-components'
import { Howl } from 'howler'

const PLAY_COLOR = '#5C7C7C'
const LOADED_COLOR = '#4C5C5C'
const LOADING_COLOR = '#3C3C3C'

AFRAME.registerPrimitive('a-speaker', {
  defaultComponents: {
    speaker: {},
    spinner__x: {
      speed: 0.01,
      direction: 'x',
    },
    spinner__y: {
      speed: -0.05,
      direction: 'y',
    },
    spinner__z: {
      speed: 0.1,
      direction: 'z',
    },
    geometry: {
      primitive: 'icosahedron',
      detail: 1,
    },
    material: {
      color: LOADING_COLOR,
      flatShading: true,
    },
  },

  mappings: {
    sources: 'speaker.sources',
  },
})

export interface SpeakerData {
  sources: string[]
}

@component('speaker')
export class Speaker extends BaseComponent<SpeakerData> {
  static schema = {
    sources: { type: 'array' },
  }
  static multiple = false
  isLoaded: boolean = false

  init(): void {
    const el = this.el

    const sound = new Howl({
      src: this.data.sources,
      autoplay: false,
      volume: 1,
      preload: true,
      html5: true,
    })
    sound.pos(el.object3D.position.x, el.object3D.position.y, el.object3D.position.z)

    sound.on('load', () => {
      this.isLoaded = true
      el.classList.add('interactive')
      el.setAttribute('material', 'color', LOADED_COLOR)
      el.addEventListener('click', () => {
        if (sound.playing()) {
          sound.pause()
          el.setAttribute('spinner__y', { speed: -0.05, local: true })
          el.setAttribute('spinner__x', 'speed', 0.01)
          el.setAttribute('spinner__z', 'speed', 0.1)
          el.setAttribute('material', 'color', LOADED_COLOR)
        } else {
          sound.play()
          el.setAttribute('spinner__y', { speed: 0.5, local: false })
          el.setAttribute('spinner__x', 'speed', 0)
          el.setAttribute('spinner__z', 'speed', 0)
          el.setAttribute('material', 'color', PLAY_COLOR)
        }
      })
    })
    el.addEventListener('mouseenter', () => {
      el.setAttribute('material', 'color', PLAY_COLOR)
    })
    el.addEventListener('mouseleave', () => {
      el.setAttribute('material', 'color', this.isLoaded ? LOADED_COLOR : LOADING_COLOR)
    })
  }
}
